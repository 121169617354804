import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import { auth, settings } from "./reducers";

const history = createBrowserHistory();
const middleware = routerMiddleware(history);

const initialState = {};

const reducers = {
  auth,
  settings,
  router: connectRouter(history)
};

const reducer = combineReducers(reducers);
const enhancers = [];

if (process.env.NODE_ENV === "dev") {
  /* eslint-disable no-underscore-dangle */
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}
const composedEnhancers = compose(
  applyMiddleware(middleware),
  ...enhancers
);
/* eslint-disable no-underscore-dangle */
const store = createStore(reducer, initialState, composedEnhancers);

export { history, store };
