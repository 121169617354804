import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import Loadable from "react-loadable";
import "./App.scss";
import { Provider } from "react-redux";
import { store, history } from "./store";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading
});

const Register = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading
});

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading
});

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/admin/" name="Home" component={DefaultLayout} />
            <Route exact path="/" name="Iniciar Sesión" component={Login} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
