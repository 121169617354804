console.log(process.env.REACT_APP_API_URL);
const initialState = {
  api_url: process.env.REACT_APP_API_URL,
  imagesFolder: "https://virtualmedia.chateandogratis.org/public/uploads"
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default reducer;
